body {
  /* font-family: 'Mulish', 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial; */
  font-family: 'Mulish';
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

.bg-hd {
  background: #fff;
  /* border: 2px solid #2e2be2; */
}

.nav-link {
  color: #808080;
}
.login-link {
  color: #808080;
  text-decoration: 'none';
  font-size: 18px;
  border-radius: 10px;
}

.login-link:hover {
  color: #2e2be2;
}

/* .nav-link:hover {
  background: #2d2ba7;
  color: #fff !important;
  text-decoration: none !important;
  border-radius: 10px;
} */

.nav-link:hover {
  color: #808080;
}

.navbar-nav .nav-link.active {
  color: #808080;
  font-size: 18px;
}

.logo-hd {
  width: 150px;
  height: auto;
}

.lightblue-btn {
  border-radius: 50px;
  color: #333;
  font-weight: bold;
  background: #d5d5f9;
  border-color: #d5d5f9;
}

.signbtn,
.subscrb-btn {
  border-radius: 50px;
  color: #fff;
  font-weight: bold;
  background: #2d2ba7;
  border-color: transparent;
}

.signbtn button.btn:hover {
  background: #e5c600;
}

.signbtn {
  padding: 5px 15px;
}

.signbtn-bnr {
  padding: 5px 30px;
  border-radius: 50px;
  color: #2d2ba7;
  font-weight: bold;
  background: #fff;
  border-color: transparent;
}

.blu-btn {
  padding: 5px 30px;
  border-radius: 50px;
  color: #fff;
  font-weight: bold;
  background: #2d2ba7;
  border-color: transparent;
}

.srchbtn,
.btn-opt {
  padding: 5px 20px;
}

.srchbtn {
  background: #2d2ba7;
  border-color: #2d2ba7;
  color: #fff;
}

.bnnr-box {
  width: 100%;
  height: 100%;
  position: relative;
}

.bnr-hd {
  max-width: 500px;
  text-align: left;
}

.bnr-hd h2 {
  font-weight: bold;
  font-size: 38px;
}

.colorbg-one {
  background-color: #2e2be1;
}

.colorbg-two {
  background-color: #2d2ba7;
}

.colorbg-three {
  background-color: #00bbcf;
}

.layer-col,
.layer-col-reverse {
  width: 100%;
  text-align: center;
  margin: 0;
}

.layer-col .bx-ly,
.layer-col-reverse .bx-ly {
  padding: 30px 20px 0;
}

.layer-col {
  background: #f2f3f5;
}

.top-insd-layer h3 {
  font-size: 16px;
}

.layer-colbox {
  position: relative;
  /* overflow: hidden; */
}

.combinebox {
  position: relative;
  text-align: center;
}

.ins-singlebx-left,
.ins-singlebx-right {
  width: 315px;
  padding-top: 70px;
  display: block;
  margin: 0 auto;
}

.ins-singlebx-right {
  padding-bottom: 170px;
}

.hd-titles-mv {
  color: #2d2ba7;
  font-size: 28px;
  font-weight: bold;
}

.hd-titles {
  color: #2e2be2;
  font-size: 34px;
  font-weight: bold;
}

.hd-titles-white {
  color: #fff;
  font-size: 34px;
  font-weight: bold;
}

.blu-t {
  color: #2d2ba7;
}

.blupicbx {
  position: relative;
}

.blubg-below {
  background: #2e2be2;
  height: 200px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  /* margin-bottom: -40px; */
}

.blubg-belowtwo {
  background: #2d2ba7;
  height: 200px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  /* margin-bottom: -40px; */
}

.intrbx .nav-tabs {
  border: 1px solid #ccc;
}

/* .intrbx .nav-link:hover {
    background: unset;
    color: unset !important;
} */

.intrbx .nav-tabs .nav-link {
  font-size: 0.8rem;
  font-weight: bold;
  white-space: nowrap;
}

.intrbx .nav-link {
  color: #333;
}

.intrbx .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  /* border: 2px solid #999; */
  background: #2d2ba7;
  color: #fff;
  border-radius: 0;
}


/* .blubg-below-sm {
    background: #2e2be2;
    height: 150px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
} */

.blupicbx img {
  width: 100%;
  margin-top: -140px;
  margin-bottom: 49px;
}

.xblupicbx {
  width: 100%;
  margin-bottom: 49px;
}

.yelow-square {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  width: 70px;
  height: 70px;
  margin-right: -1em;
  margin-bottom: 1em;
}

.wrap-yelo {
  position: relative;
}

.relbox {
  position: relative;
}

.drop-shadow{
  box-shadow: 5px 5px 5px rgba(0,0,0,0.3);
}

.bgthirdrow {
  background-color: #f2f3f5;
  background-size: cover;
}

.bfr-lightbgclr::before,
.bgthirdrow::before,
.raise-bnr::before,
.invstbnr::before,
.evntsbnr::before,
.comp-bnr::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 0;
}

.whte-colorblur::before {
  background-color: rgb(255 255 255 / 86%);
}

.mrewhte-colorblur::before {
  background-color: rgb(8 194 213 / 80%);
}

.left-alignbx {
  text-align: center;
}

.full-w {
  width: 100%;
}

.blugrn {
  color: #00bbcf;
}

.tbold {
  font-weight: bold;
}

.patternblue-img {
  position: absolute;
  width: 45px;
  top: 0;
  left: 0;
  margin-top: 130px;
}

.patternblue-imgtwo {
  position: absolute;
  width: 65px;
  top: 0;
  left: 0;
  margin-top: 180px;
}

.dotblue {
  position: absolute;
  width: 55px;
  top: 0;
  left: 0;
}

.btmblue {
  height: 100px;
  background-color: #2e2be2;
  background-size: cover;
  /* position: relative; */
}

.hd-colr {
  color: #2e2be2;
}

.btmblue::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgb(255 255 255 / 10%);
  z-index: 0;
}

.chtext {
  /* width: 250px; */
  display: block;
  margin: 0 auto;
  font-weight: bold;
  color: #333;
}

.nfull-w {
  width: 90%;
}

.txtleft {
  text-align: left;
}

.lightgrn-brline {
  background-color: #67c9c4;
  height: 20px;
}

.hopw-hbox {
  background: #f2f3f5;
  overflow: hidden;
}

.flowbx {
  background: #1b1aae;
  overflow: hidden;
}

.sqr-r {
  width: 135px;
  rotate: 50deg;
  position: absolute;
  top: 9px;
  bottom: 0;
  right: 36px;
  z-index: 0;
  display: none;
}

.sqr-rleft {
  background: #5271ff;
}

.sqr-rmid {
  background: #2e2be2;
}

.rectbgclr {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* margin-right: 98px; */
  z-index: 2;
}

.arw-left {
  background: #5271ff;
}

.arw-mid {
  background: #2e2be2;
}

.arw-right {
  background: #1b1aae;
}

/* .hd-leftp {
    padding-left: 37px;
} */

.height-arw {
  width: 100%;
  height: 125px;
}

/* .height-arw::before {
    content: "\f0da";
    display: inline-block;
    font-size: 20px;
    color: #5271ff;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
} */

.abs-cntericon {
  position: absolute;
  width: 78px;
  /* margin-left: 70px; */
  margin-top: -48px;
}

.abs-prelfticon {
  position: absolute;
  width: 78px;
  right: 0;
  margin-right: 85px;
  margin-top: -48px;
}

.abs-postrghticon {
  position: absolute;
  width: 61px;
  right: 0;
  margin-right: 85px;
  margin-top: -59px;
}

.numcbglight {
  background: #00bbcf;
}

.numbgylw {
  background: #ffda00;
}

.numcbgdrk {
  background: #2d2ba6;
}

.numc {
  width: 70px;
  height: 70px;
  padding: 11px;
  font-size: 27px;
  font-weight: bold;
}

.right-hopw {
  text-align: left;
  width: 205px;
}

.bleuc {
  color: #2d2ba6;
}

.hdlft-hopw {
  /* color: #00bbcf; */
  color: #2e2be2;
  font-weight: bold;
}

.hdlft-hopwylw {
  color: #ffda00;
  font-weight: bold;
}

.hdcntr-hopw {
  color: #2d2ba6;
  font-weight: bold;
}

.shdwlght {
  box-shadow: 10px 10px #00bbcf;
}

.shdwlghtsml {
  box-shadow: 4px 4px #00bbcf;
}

.smll-crcl {
  /* width: 20px;
    height: auto; */
  border-radius: 50px;
  padding: 8px;
}

ul.lst-lgd {
  list-style: none;
  padding: 0;
}

ul.lst-lgd li {
  display: inline-block;
}

.fundrbox {
  background: #eff6f6;
}

.carouselbx {
  border-radius: 30px;
  background: #fff;
  overflow: hidden;

  box-shadow: 0px 3px 27px -5px #e1e1e1;
}

.carosize {
  height: 190px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
}

.caro-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 3px solid #e4e4e4;
  background: #fff;
}

.name-btm {
  /* display: flex;
    align-items: end; */
  font-weight: bold;
}

.avatarbx {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.avatarbx::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgb(255 255 255 / 65%);
  z-index: 0;
}

.smalltabs {
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 12px;
  margin: 4px;
  background: #ebe9e9;
}

.smalltabs-green {
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 12px;
  margin: 4px;
  background: #daf7f8;
}

.vmblu-btn {
  background: #0038a8;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  border: 1px solid transparent;
}

.vmblu-btn:hover {
  background: transparent;
  border: 1px solid #0038a8;
  color: #0038a8;
}

.static-btn {
  background: #0038a8;
  border-radius: 20px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  border: 1px solid transparent;
}

.slf-overlay {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 52%,
    rgba(0, 0, 100, 0)
  );
  height: 120px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.top-icons-caro {
  position: relative;
  z-index: 4;
}

.hd-titles-whte {
  color: #fff;
  font-size: 34px;
  font-weight: bold;
}

.curvebx {
  border-radius: 30px;
  background: #f8f6f7;
  overflow: hidden;
}

.curvebx p {
  text-align: left;
  font-size: 12px;
}

.shdwbx {
  box-shadow: 0px 0px 12px 0px #333;
}

.shdwbx-iac {
  box-shadow: 1px 12px 20px #dadada;
}

.fulheight {
  height: 100%;
}

.cf-icons {
  width: 70px;
}

.padd-med {
  padding: 0 20px;
}

.sfbg {
  background: #f2f3f5;
  overflow: hidden;
}

.hdsf-titles {
  font-size: 34px;
  font-weight: bold;
}

.details-cntrmid {
  padding: 9% 15%;
}

.text-left {
  text-align: left;
}

.hidn-flw {
  overflow: hidden;
}

.bluebg-sqr {
  background-size: cover;
}

/* .bluebg-sqr::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgb(255 255 255 / 86%);
    z-index: 0;
} */

.hpylk a {
  color: #fff;
  text-decoration: none;
}

.hpylk a:hover {
  text-decoration: underline;
}

.subbx input.form-control {
  border-radius: 50px !important;
}

.subbx .form-control {
  padding: 0.375rem 3.75rem 0.375rem 0.75rem;
}

.addtnl {
  margin-left: -48px;
  z-index: 5 !important;
}

.footlogo {
  width: 200px;
  height: auto;
}

.circle-icons {
  border-radius: 50px;
  padding: 8px;
  background: #d5d4f9;
  color: #2e2ca7;
}

.circle-icons i {
  font-size: 30px;
}

.ft-sub a {
  color: #333;
  text-decoration: none;
}

.btm-socialbx {
  background: #d5d4f9;
}

.social-rlogo {
  width: 40px;
  height: auto;
  background: #fff;
  padding: 7px;
  border-radius: 50px;
  margin-left: 10px;
}

.fsize-icon {
  font-size: 30px;
}

.topscl-info {
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 450px;
}

.topscl-info img {
  width: 100%;
}

.th-scl {
  color: #3f3b4f;
  font-weight: bold;
}

.annmt-bg {
  background: #fff;
  padding-bottom: 80px;
  overflow: hidden;
}

.ronbgbx {
  background: #fafafa;
  padding-bottom: 80px;
  overflow: hidden;
}

.itembgcrsl {
  background: #fff;
  padding: 20px;
}

.img-leftcrsl {
  display: block;
  border: 1px solid #999;
  box-shadow: 0px 0px 8px 0px #333;
}

.txt-hdcarsl {
  color: #2e2be2;
  font-size: 24px;
  font-weight: bold;
  text-transform: capitalize;
}

.main-crslbox {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.main-crslbox::before,
.halfdropbx::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50px;
  right: 50px;
  bottom: 0;
  box-shadow: 0px 3px 27px -5px #e1e1e1;
}

.halfdropbx::before {
  z-index: -1;
}

.halfdropbx::after {
  background: #fff;
  z-index: 0;
}

.font-italic {
  font-style: italic;
}

.font-weight-bold {
  font-weight: bold;
}

.mble-btn {
  padding: 0 10px;
}

/* .main-crslbox .carousel-control-next {
    margin-right: -100px;
}

.main-crslbox  .carousel-control-prev {
    margin-left: -100px;
} */

.main-crslbox .carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: #333;
}

.main-crslbox .carousel-indicators [data-bs-target] {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #fcd116;
  margin-right: 10px;
  margin-left: 10px;
}

.main-crslbox .carousel-indicators {
  margin-bottom: -55px;
}

.mrg-btm {
  margin-top: 72px;
}

.bgnws {
  background: #fff;
  border-radius: 30px;
  box-shadow: 0px 3px 27px -5px #e1e1e1;
}

.left-imgnws {
  background-position: center;
  background-size: cover;
  border-radius: 30px;
  overflow: hidden;
  height: 180px;
}

.right-detailsnws {
  padding: 20px;
}

.txt-clamp {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.txt-clamp-two {
    display: -webkit-box;
    overflow: hidden;
    white-space: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.txt-clamp-single {
    display: -webkit-box;
    overflow: hidden;
    white-space: normal;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

/* .arrowsd::before,
.arrowsd::after {
    content: "";
    width: 130px;
    height: 50px;
    background-image: url("../assets/images/arrow-curve.png");
    background-size: contain;
    background-position: center;
    display: none;

} */

.ylw-graphic {
  width: 80px;
  position: absolute;
  right: 0;
  margin-right: -19px;
  bottom: 10%;
}

.ylw-graphic-two {
  width: 92px;
  position: absolute;
  right: 0;
  margin-right: -19px;
  top: 0;
}

.sz-pw-icon {
  width: 65px;
  height: max-content;
}

.wdthbx-abs {
  width: 32.5%;
}

.no-pads.row > * {
  padding: 0;
}

.rightbx-bnr {
  width: 55%;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;
  /* visibility: hidden; */
}

.absbx {
  position: absolute;
}

.fixbox {
  position: fixed;
}

.alertbx {
  bottom: 0;
  z-index: 1001;
}

.arw-invi .carousel-control-prev,
.arw-invi .carousel-control-next {
  visibility: hidden;
}

.staricon {
  width: 55px;
  position: absolute;
  left: 0;
  margin-top: -53px;
  margin-left: -30px;
  top: 0;
}

.rocketicon {
  width: 176px;
  position: absolute;
  left: 0;
  margin-top: -64px;
  margin-left: -111px;
  top: 0;
  display: none;
}

.megaphoneicon {
  width: 102px;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: -48px;
  margin-right: 74px;
  display: none;
}

/*UI SCROLLBAR */
.scrollbar {
  overflow-y: scroll;
}

.scrollbar-primary {
  scrollbar-color: #4285f4 #f5f5f5;
}

.scrollbar-primary::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #4285f4;
}

.scrollbar-danger {
  scrollbar-color: #ff3547 #f5f5f5;
}

.scrollbar-danger::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scrollbar-danger::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.scrollbar-danger::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #ff3547;
}

.scrollbar-warning {
  scrollbar-color: #ff8800 #f5f5f5;
}

.scrollbar-warning::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scrollbar-warning::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.scrollbar-warning::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #ff8800;
}

.scrollbar-success {
  scrollbar-color: #00c851 #f5f5f5;
}

.scrollbar-success::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scrollbar-success::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.scrollbar-success::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #00c851;
}

.scrollbar-info {
  scrollbar-color: #33b5e5 #f5f5f5;
}

.scrollbar-info::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scrollbar-info::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.scrollbar-info::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #33b5e5;
}

.scrollbar-default {
  scrollbar-color: #2bbbad #f5f5f5;
}

.scrollbar-default::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scrollbar-default::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.scrollbar-default::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #2bbbad;
}

.scrollbar-secondary {
  scrollbar-color: #aa66cc #f5f5f5;
}

.scrollbar-secondary::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scrollbar-secondary::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.scrollbar-secondary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #aa66cc;
}

/*UI SCROLLBAR END*/

.color-fb {
  color: #2D2BA7;
}

.color-yt {
  color: #ed2224;
}

/* .arwbig-right::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    content: "\f04b";
    position: absolute;
    z-index: 100;
    right: 0;
    margin-top: 19%;
} */

a.shr-hvr,
a.heart-hvr {
  color: #666;
  font-size: 16px;
}

a.shr-hvr:hover {
  color: #333;
}

a.heart-hvr:hover {
  color: #e5554f;
}

.btn-text {
  font-size: 12px;
  color: #666;
}

.navbarSticky {
  width: 100%;
  position: fixed;
  top: 0;
  /* padding: 53px 0 20px; */
  transition: all 0.3s;
  z-index: 1001;
}

.navbarFixedToTop {
  position: fixed;
  top: 0;
  transition: all 0.3s;
  width: 100%;
  margin: 0;
  /* padding: 20px; */
  box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
}

.mrgntp-hdr {
  /* margin-top: 125px; */
  margin-top: 85px;
}

.raise-bnr::before {
  background-color: rgb(44 42 148 / 80%);
}

.comp-bnr::before,
.evntsbnr::before {
  background-color: rgb(53 49 255 / 80%);
}

.invstbnr::before {
  background-color: rgb(245 62 66 / 80%);
}

.raise-bnr,
.invstbnr,
.evntsbnr {
  background-size: cover;
  height: 90vh;
}

.widhdr {
  width: 100%;
  margin: 0 auto;
  color: #fff;
}

.widhdr h2 {
  padding-top: 30px;
  font-weight: bold;
}

.srchbox {
  background: #fff;
  border-radius: 30px;
  padding: 30px 40px 20px;
  margin: 0 auto;
  width: 100%;
}

.srchboxlrn {
  background: #fff;
  border-radius: 30px;
  padding: 30px 40px;
  margin: 0 auto;
  width: 100%;
}

.srchboxlrn
  .input-group:not(.has-validation)
  > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select,
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ) {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback,
.ron-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.ron-search .form-control {
  padding-left: 2.375rem;
  border-radius: 50px;
  background: #f2f2f2;
}

.ron-search {
  width: 70%;
  display: block;
  margin: 0 auto;
}

.bg-insd-ron {
  width: 80%;
  background: #fff;
  margin: 0 auto;
  border-radius: 30px;
  box-shadow: 0px 3px 27px -5px #e1e1e1;
}

.iacbox {
  width: 80%;
  margin: 0 auto;
}

.widsml {
  width: 50%;
  margin: 0 auto;
}

.semi-bradius {
  border-radius: 50px;
}

.btn-opt {
  background: #ebebeb;
}

.btn-rightb button {
  margin-right: 15px;
}

.insd-img img {
  width: 80px;
  height: 80px;
}

.center-mble {
  justify-content: center;
  display: flex;
}

.cnterflxm {
  justify-content: center;
}

.regsbox {
  background: #e6e7e9;
}

.num-stp {
  border-radius: 100%;
  width: 32px;
  height: auto;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 31px;
}

.shp-butn {
  border-radius: 50px;
  padding: 1px 15px;
}

.blutwo {
  color: #2b2aaf;
}

.lessthn::before {
  content: '>';
  padding: 0 3px;
}

.icn-circle {
  width: 60px;
  height: auto;
  position: absolute;
  margin-top: -17px;
}

.edgblu-curvebx {
  padding: 10px 20px 10px 73px;
  background: #2b2aaf;
  color: #fff;
  border-radius: 50px;
  margin-top: 10px;
}

.docbox {
  width: 50px;
  height: auto;
  margin: 0 auto;
}

.ylw-vrfdbx {
  background: #ABAAF3;
  border-radius: 50px;
  color: #1b1aae;
  padding: 20px 30px;
}

.brnchbox {
  height: 100%;
}

.lghtblue {
  background: #dbdbf3;
}

.btns-srch {
  justify-content: space-between;
}

.slctbox select,
.slctbox select:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.optionbtn {
  width: 140px;
}

.btns-srch > div {
  margin-top: 10px;
}

.colortxt-rsk {
  color: #e15c63;
}

.bblue {
  color: #2a85f4;
}

.wid-svn {
  width: 70%;
}

.wtkm-bx {
  position: relative;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}

.wtkm-bx::before {
  background-color: rgb(70 74 145 / 40%);
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 0;
}

.viobgbx {
  background: #e7e8ff;
}

.blu-oe {
  background: #2e2be2;
  position: absolute;
  top: 0;
  left: -20px;
  right: -200px;
  height: 62px;
  width: 300%;
  z-index: -1;
  border-radius: 2em 0 0 2em;
}

.pre-hd {
  background: #5271ff;
  height: 120px;
}

.cpgn-hd {
  background: #2e2be2;
  height: 120px;
}

.post-hd {
  background: #1b1aae;
  height: 120px;
}

.brdrleft {
  border-left: 5px solid #2e2be2;
  padding-left: 20px;
}

.padds-hpw {
  padding: 35px 45px;
}

.sub-des {
  font-size: 22px;
}

.bg-lights {
  background: #f2f3f5;
}

.brdr-rdis-uea {
  border-radius: 20px;
  overflow: hidden;

  height: 270px;
  background-position: 25% 3%;
  background-size: cover;
}

.imgtop-ron {
  overflow: hidden;
  height: 250px;
  border-radius: 30px;

  background-position: 25% 40%;
  background-size: cover;
}

.pplink {
  background: #f07141;
  border-radius: 50px;
  padding: 5px 8px;
  font-size: 10px;
  color: #fff;
  text-decoration: none;
}

.txtsmall {
  font-size: 11px;
}

.v-prg {
  background-color: #d5d5f9;
  /* border: 1px solid #999; */
}

.overv-prg {
  background-color: #090869;
  /* border: 1px solid #999; */
}

.mdl-clsebtn .btn-close {
  border-radius: 50%;
  border: 2px solid #333;
}

.plus-icon {
  line-height: 2rem !important;
}

.frm-indstry {
  padding: 0.375rem;
  font-size: 0.8rem;
  background-color: #ebe9e9 !important;
}

.bgselect-drp {
  background-color: #ebe9e9 !important;
}

.circle-ira {
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto;

  height: 200px;
  /*this can be change or delete*/
  width: 200px;
  /*this can be change or delete*/
}

.circle-ira img {
  width: 100%;
  height: 200px;
}

.bg-bldgbox {
  background-position: bottom;
  background-color: #fff;
  background-repeat: no-repeat;
  padding: 65px 0 20px;
}

.shadw {
  box-shadow: 0px 3px 27px -5px #e1e1e1;
}

.bdr-xsemi {
  border-radius: 20px;
}

.insd-rc {
  width: 100%;
}

.grn-exclmtry {
  width: 280px;
}

.mrgn-cntr {
  margin: 0 auto;
}

.env-sz {
  width: 45px;
}

.env-szheight {
  height: 50px;
}

.hdw-sz {
  height: 60px;
}

.num-ylw {
  padding: 2px 10px;
  background: #abaaf3;
  color: #2d2ba7;
  font-weight: bold;
  border-radius: 50%;
}

.hvr-info {
  cursor: pointer;
}

.smllw {
  width: 170px;
  margin-right: 1.5em;
}

.bgbox-hs {
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}

.trnsprntbgc {
  background-color: transparent;
}

.mbtm-tblues {
  margin-bottom: 86px;
}

.height-cu {
  height: 300px;
  overflow: hidden;
}

.yth {
  width: 100%;
  height: 350px;
}

.ctc-hs {
  height: 500px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.caroulcrvbx,
.hb0x {
  height: 200px;
}

.brdr-cntr {
  border-right: 2px solid #333;
}

.crd-brknlne {
  border: 2px dashed #999;
}

.sp-lithbg {
  background: #f8f9fd;
}

.dtlsbx-annc {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.add-elps {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.dless {
  list-style: none;
}

ul.dless li {
  padding: 5px 0;
}

.linkin {
  font-size: 12px;
  line-height: 27px;
  padding: 1px;
}

.user-icn {
  border-radius: 50%;
  border: 2px solid #2d2ba7;
  padding: 30px 16px 0;
  font-size: 90px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  color: #2d2ba7;
}

.user-small {
  border-radius: 50%;
  border: 2px solid #00b4b7;
  padding: 10px 7px 0;
  font-size: 33px;
  overflow: hidden;
  width: 45px;
  height: 45px;
  color: #fff;
  background: #00b4b7;
}

.user-smaller {
  border-radius: 50%;
  border: 2px solid #00b4b7;
  padding: 7px 2px 0;
  font-size: 26px;
  overflow: hidden;
  width: 35px;
  height: 35px;
  color: #fff;
  background: #00b4b7;
}

.files-icn {
  font-size: 34px;
}

.listline::before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50px;
  background-color: #7d7d7d;
  display: inline-flex;
  margin-left: 8px;
  margin-right: 6px;
}

.brdrlightc {
  border: 1px solid #ccc;
}

.small-brdrds {
  border-radius: 15px;
}

.text-small {
  font-size: 12px;
}

.replybx {
  border-left: 2px solid #ccc;
  padding-left: 10px;
  margin-left: 20px;
  margin-top: 20px;
}

.accbx .accordion-item .accordion-button,
.fqbx .accordion-item .accordion-button {
  border-radius: 0;
  font-weight: bold;
  box-shadow: unset;
}

.fqbxx .accordion-item .accordion-button,
.fqbx .accordion-item .accordion-button {
  border-radius: 0;
  font-weight: bold;
}

.accbx .accordion-item {
  border-left: 0;
  border-right: 0;
  border-color: #333;
  border-radius: 0;
}

.accbx .accordion-button:not(.collapsed),
.fqbx .accordion-button:not(.collapsed),
.fqbxx .accordion-button:not(.collapsed),
.fqbx .accordion-button:not(.collapsed) {
  background-color: unset;
}

.accbx .accordion-button:focus,
.fqbx .accordion-button:focus {
  box-shadow: unset;
}

.fqbxx .accordion-button:focus {
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

.fqbx .accordion-item {
  border-color: #999;
  border-radius: 0;
}

.inv-btn {
  width: 100%;
  font-weight: bold;
  font-size: 18px;
}

.lnkk {
  text-decoration: none;
}

.lnkk:hover {
  text-decoration: underline;
}

.hvr-icn {
  height: 50px;
  width: 41px;
  cursor: pointer;
  background-repeat: no-repeat;
}

.lnk-icn {
  background: transparent;
  outline: none;
  padding: 15px 0;
}

.lnk-icn:hover {
  background: #eee;
  border-radius: 10px;

  cursor: pointer;
}

.whitebg {
  background: #fff;
  padding: 20px;
}

.hdr-fq {
  padding: 10px 15px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid #999;
  border-bottom: 0;
}

.fq-link a {
  text-decoration: none;
  color: #333;
  outline: none;
}

.fq-link a:hover {
  color: #2d2ba7;
}

.wdth-mxmble {
  width: 350px;
}

.scroll-to-top {
  border-radius: 50% !important;
  fill: #2d2ba7 !important;
  width: 50px !important;
  height: 50px !important;
}

.scrll-up svg {
  width: 40px;
  height: 40px;
  fill: #2d2ba7;
}

.fb-iconbg {
  background: #1877f2;
}

.pink-colorbg {
  background: #e75e8c;
}

.linkedin-iconbg {
  background: #0277b5;
}

.fb-pads {
  padding: 4px 7px;
}

.insta-pads {
  padding: 5px 7px;
}

.yt-colorbg {
  background: #ff2727;
}

.yt-pads {
  padding: 5px;
}

.fit-content {
  width: fit-content;
}

.text-small-xs {
  font-size: 10px;
}

.lightgraybg {
  background: #999;
}

/* .hvr-icn:hover {

} */

/* .modal-header .btn-close {
    margin: calc(-10.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
    border-radius: 100%;
    border: 3px solid;
} */

.arw-invi .carousel-item {
    /* height: 850px; */
    height: 90vh;
}

/* .vslebx {
    visibility: visible;
    transition: .3s;
} */

@media (min-width: 992px) {
  .rightbx-bnr,
  .megaphoneicon,
  .rocketicon {
    /* visibility: visible; */
    display: block;
    transition: 0.3s;
  }

  .arrowsd::before,
  .arrowsd::after {
    display: inline-block;
  }

  .center-mble {
    justify-content: unset;
    display: unset;
  }

  .left-alignbx {
    text-align: left;
  }

  .whitebg {
    background: none;
  }

  .cnterflxm {
    justify-content: unset;
  }

  .btns-srch {
    justify-content: space-around;
  }

  .widhdr {
    width: 40%;
  }

  .srchbox,
  .srchboxlrn {
    width: 70%;
  }

  .btns-srch > div {
    margin: 0;
  }

  .insd-rc {
    width: 360px;
  }
}

/* @media (min-width: 576px) {
    .modal {
        --bs-modal-margin: 4.75rem;
    }
} */

.dot-flashing {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #817fed;
  color: #817fed;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -50px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #817fed;
  color: #817fed;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 50px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #817fed;
  color: #817fed;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #817fed;
  }
  50%,
  100% {
    background-color: rgba(213, 212, 249, 0.5);
  }
}

.screen {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
  height: 100vh !important;
  width: 100vw !important;
  background: '#fff';
  opacity: 0;
  z-index: 9999;
  transition: visibility 0s .5s, opacity .5s linear;
  background-color: rgba(255,255,255, 1);
  pointer-events: none;
}

.fade-out-screen {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh !important;
    width: 100vw !important;
    opacity: 1;
    background-color: rgba(255,255,255, 1);
    z-index: 9999;
    pointer-events: none;
}

img{
  pointer-events: none;
}

.req-box{
  background-color: #EEF2F5;
  border-radius: 25px;
  padding-top: 10px;
}

.investor-types{
  background-color: #d5d5f9 ;
  padding: 1em 1em;
  border-radius: 30px;
}

.info-icon{
  font-size: .7em;
}

.investbtn-bnr {
  padding: 5px 30px;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  background: #f30407;
  border-color: transparent;
}

.investbtn-bnr:hover {
  color: #fff;
  background: #ec6163;
  border-color: #ec6163;
}